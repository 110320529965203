<!-- 生成实验报告弹窗 -->
<template>
    <a-modal :width="600" :visible="visible" @cancel="handleCancel" title="生成实验模板">
        <a-spin :spinning="loading" tip="生成中">
            <a-form-model style="width: 80%;margin: 0 auto;" ref="formRef" :model="formData" :colon="true" :labelCol="{
            xs: { span: 24 },
            sm: { span: 5 },
        }" :wrapper-col="{ span: 19 }">
            <a-form-model-item label="文件名" prop="fileName" :rules="[{ required: true, message: '请输入文件名' }]">
                <a-input v-model="formData.fileName"></a-input>
            </a-form-model-item>
            <a-form-model-item label="文件类型" prop="fileType" required>
                <a-select v-model="formData.fileType">
                    <a-select-option :value="`.pdf`"> pdf文件（.pdf） </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="报告内容" prop="content" :rules="[{ required: true, validator: contentValidator }]">
                <a-checkbox :defaultChecked="true" @change="(e) => onCheckedChange(e, 'report')">
                    实验报告
                </a-checkbox>
            </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" :disabled="loading" :loading="loading" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :disabled="loading" :loading="loading" class="confirm-btn" @click.prevent="hanleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { ref } from '@vue/composition-api';
import { useThrottleFn } from '@vueuse/shared';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        isTemplate: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        // 对话框取消
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 表格ref
        const formRef = ref(null);
        // 表格数据
        const formData = ref({
            fileName: '实验报告',
            fileType: '.pdf',
            content: ['report']
        });
        // 加载中
        const loading = ref(false);
        // 确定按钮点击
        const hanleConfirm = useThrottleFn(() => {
            formRef.value.validate().then(() => {
                //context.emit('update:visible', false);
                loading.value = true;
                context.emit('confirmMakeReport', `${formData.value.fileName}${formData.value.fileType}`)
            }).catch(e => {
                console.log(e)
            });
        }, 3000)
        // 报告内容勾选
        const onCheckedChange = (e, type) => {
            if (e.target.checked) {
                formData.value.content.push(type);
            }
            else {
                const index = formData.value.content.indexOf(type);
                index !== -1 && formData.value.content.splice(index, 1);
            }
        }
        // 内容校验器
        const contentValidator = async (rule, value, callback) => {
            if (value.length < 1) {
                return callback(new Error('请选择报告内容'))
            }
            else {
                callback();
                return callback();
            }
        }
        return {
            handleCancel,
            formRef,
            formData,
            onCheckedChange,
            hanleConfirm,
            contentValidator,
            loading
        }
    }
}
</script>
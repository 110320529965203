// 实验模板 接口
import  request from "../utils/request";

// 实验模板初始化数据
export const getExperimentTemplateInitData = (params) => {
    return request({
        method: 'get',
        url: `/experimentTemplate/experimentTemplateInit`,
        params
    })
}

// 添加实验模板
export const addExperimentTemplate = (data) => {
    return request({
        method: 'post',
        url: `/experimentTemplate/addExperimentTemplate`,
        data
    })
}

// 分页查询我的模板
export const getMyTemplateList = (params) => {
    return request({
        method: 'get',
        url: `/experimentTemplate/pageList`,
        params
    })
}

// 获取模板详情
export const getTemplateDetail = (params) => {
    return request({
        method:'get',
        url: '/experimentTemplate/detail',
        params
    })
}

// 删除模板
export const deleteTemplate = (data) => {
    return request({
        method: 'post',
        url: `/experimentTemplate/delete`,
        data
    })
}

// 分享模板到模板中心
export const shareExperimentTemplate = (params) => {
    return request({
        method: 'get',
        url: `/experimentTemplate/shareExperimentTemplate`,
        params
    })
}

// 更新实验模板
export const editExperimentTemplate = (data) => {
    return request({
        method: 'post',
        url: `/experimentTemplate/editExperimentTemplate`,
        data
    })
}

// 设置项目实验模板——初始化数据
export const getSetProjectExperimentTemplateIntitData = (params) => {
    return request({
        method: 'get',
        url: `/experimentTemplate/myExperimentTemplateList`,
        params
    })
}

// 设置项目实验模板
export const setProjectExperimentTemplate = (data) => {
    return request({
        method: 'post',
        url: `/experimentTemplate/setProjectExperimentTemplate`,
        data
    })
}
<!-- 添加实验类型 -->
<template>
    <a-modal :width="600" :visible.sync="visible" @cancel="handleCancel" title="添加实验类型" >
        <a-spin :spinning="isSubmitting" content="提交中">
            <a-form-model ref="formRef" :model="formData" :labelCol="{ span: 6}" :wrapperCol="{ span: 14 }" :rules="rules">
                <a-form-model-item prop="name" label="实验类型名称：" >
                    <a-input v-model="formData.name" placeholder="请输入" :maxLength="20"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="m-footer">
                <a-button :disabled="isSubmitting" :loading="isSubmitting" type="primary" @click="handleConfirm"> 确定 </a-button>
                <a-button v-show="!isSubmitting" :disabled="isSubmitting" :loading="isSubmitting" @click="handleCancel" > 取消 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { ref, watch } from 'vue-demi';
import { customExperimentType } from '../../../api/experiment';

export default {
    props: {
        visible: {
            default: false,
            required: true
        }
    },
    setup(props, context) {
        const formData = ref({
            name: ''
        })

        watch(()=> props.visible, (val) => {
            if(val) {
                formData.value = {
                    name: ''
                }
                formRef.value && formRef.value.resetFields();
            }
        })

        const formRef = ref();

        const handleCancel = () => {
            context.emit('update:visible', false);
        }

        const isSubmitting = ref(false);

        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async (valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await customExperimentType({
                        name: formData.value.name
                    });
                    if(res.success) {
                        message.success(`成功添加`)
                        context.emit('update:visible', false);
                        context.emit('onSuccess', res.data)
                    }
                    isSubmitting.value = false;
                }
            })
        }

        const rules = ref({
            name: [
                /* { required: true, whitespace: true,  message: '名称不能为空', trigger: ['blur', 'change']}, */
                {
                required: true,
                message: `请输入名称`,
                whitespace: true,
                trigger: ['blur', 'change']
            }, {
                max: 20,
                message: '不能超过20个字符',
                trigger: ['blur', 'change']
            }
            ]
        })

        return {
            handleCancel,
            formRef,
            formData,
            handleConfirm,
            isSubmitting,
            rules
        }
    }
}
</script>

<style>
.m-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<template>
    <div class="submitting-mask" v-show="fileSubmitting || formSubmitting">
        <div class="inner-mask">
            <div class="icon">
                <a-icon type="loading" style="font-size: 24px;color: #1890ff;" />
            </div>
            <div class="tip">
                {{ tips }}
            </div>
            <div class="file-list">
                <div v-for="(item, index) in fileList" class="file-item">
                    <div class="file-name">
                        <!-- {{ `${item.fileName}${item.fileExtensionName}` }} -->
                        {{ item.FileName ? `${item.FileName }` : `${item.fileName}${item.fileExtensionName}` }}
                    </div>
                    <div v-show="item.FileStatu === 'done'" class="file-statu">
                        <a-icon type="check-circle" theme="filled" style="color: #52C41A;background: #ffffff;border-radius: 50%;"/>
                        <span> 上传完成 </span>
                    </div>
                    <div class="file-statu" v-show="item.FileStatu === 'ready'">
                        等待上传...
                    </div>
                    <div class="file-statu" v-show="item.FileStatu === 'uploading'">
                        <a-icon type="loading" style="color: #ffffff;margin-right: 5px;" />上传中... <span v-if="item.progressPercent"> {{  item.progressPercent  }}% </span>
                    </div>
                    <div class="file-statu" v-show="item.FileStatu === 'fail'">
                        <a-icon type="close-circle" theme="filled" style="color: #FF4D4F;background: #ffffff;border-radius: 50%;"/>
                        上传失败！
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, watch,  } from 'vue-demi'

export default {
    props: {
        fileSubmitting: {
            type: Boolean,
            required: true
        },
        formSubmitting: {
            type: Boolean,
            required: true
        },
        fileList: {
            type: Array | null,
            default: () => [
            ]
        }
    },
    setup(props, context) {
        const tips = computed(() => {
            if (props.fileSubmitting) {
                return '正在上传文件中，请稍等....'
            }
            if (props.formSubmitting) {
                return '正在提交数据，请稍等....'
            }
        });
        watch(() => props.fileList, (newValue) => {
            console.log(props.fileList)
            console.log(context)
        }, {
            deep: true
        })
        return {
            tips
        }
    }
}
</script>

<style lang="less" scoped>
.submitting-mask {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-mask {
        color: #1890ff;
        width: 450px;
        text-align: center;
        .tip {
            text-align: center;
        }
        .icon {
            text-align: center;
        }
        .file-list {
            text-align: center;
            color: #ffffff;
            margin-top: 30px;
            width: 100%;
            .file-item {
                display: flex;
                justify-content: center;
                .file-name {
                    width: 0px;
                    flex-grow: 1;
                    text-align: left;
                    margin-right: 50px;
                    word-break: break-all;
                }
                .file-statu {
                    margin-left: 40px;
                    text-align: left;
                    width: 90px;
                }
            }
        }
    }
}
</style>